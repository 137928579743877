export const timeStatus = (
  openTimeDay,
  closeTimeDay,
  openTimeNight,
  closeTimeNight,
  currentTime
) => {
  let restaurantStatus;
  let newTime;

  // eslint-disable-next-line
  if (currentTime.slice(0, 2) == 24) {
    let minutes = currentTime.slice(3, 5);
    newTime = `00:${minutes}`;
  } else {
    newTime = currentTime;
  }

  // if (newTime >= openTimeNight && newTime < closeTimeDay) {
  //   restaurantStatus = "Open";
  // } else if (newTime < closeTimeNight) {
  //   restaurantStatus = "Open";
  // } else {
  //   restaurantStatus = "Closed";
  // }

  // Check if restaurant opens that day
  if (openTimeDay === "00:00" && openTimeNight === "00:00") {
    restaurantStatus = "Closed"

    // Open during the day
  } else if (newTime >= openTimeDay && newTime <= closeTimeDay) {
    restaurantStatus = "Open"

    // Open during night before day change
  } else if (newTime >= openTimeNight) {
    restaurantStatus = "Open"

    // Open after day change
  } else if (newTime <= closeTimeNight) {
    restaurantStatus = "Open"
  } else {
    restaurantStatus = "Closed"
  }

  return restaurantStatus;
};

export const getCurrentDay = (timeZone) => {
  //   Current time info
  let currentTime;
  let currentDay;

  // Get current weekday, hour and minute
  const currentInfo = new Date().toLocaleString("en-US", {
    timeZone: timeZone,
    hour12: false,
    hour: "numeric",
    minute: "numeric",
    weekday: "long",
  });

  //   Get current time depending on timezone and split in array
  const parts = currentInfo.split(" ");
  currentDay = parts[0];
  currentTime = parts[1];

  return { currentDay, currentTime };
};
