import DeliveryInfo from "../components/delivery/DeliveryInfo";

const Delivery = (params) => {
  return (
    <div>
      <DeliveryInfo />
    </div>
  );
};

export default Delivery;
