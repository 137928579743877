import HeroImg from "../../../assets/main/Hero.png";

import "./DeliveryHeroImg.css";

const DeliveryHeroImg = ({ text }) => {
  return (
    <div className="delivery-hero-wrapper">
      <div className="delivery-hero-title">
        <div className="delivery-hero-title-child">{text}</div>
      </div>
      <img src={HeroImg} alt="home img" className="delivery-hero-img" />
    </div>
  );
};

export default DeliveryHeroImg;
