import { NavLink } from "react-router-dom";
import LabKitchenLogo from "../../../assets/main/LABKITCHEN LOGO.svg";

import "./NavBar.css";

const NavBar = (params) => {
  return (
    <div className="nav-container">
      <NavLink className="nav-image-wrapper" to="/">
        <img src={LabKitchenLogo} alt="Main logo" className="nav-image" />
      </NavLink>
    </div>
  );
};

export default NavBar;
