import { useEffect, useState } from "react";
import Footer from "../utils/footer/Footer";
import DeliveryBtn from "./deliveryBtn/DeliveryBtn.js";
import DeliveryHeroImg from "./deliveryHeroImg/DeliveryHeroImg";
// import CloseIcon from "@mui/icons-material/Close";

import "./DeliveryInfo.css";

import LabKitchenData from "../../data/labKitchenData.json";

const DeliveryInfo = () => {
  const [urlName, setUrlName] = useState("");
  // const [couponStatus, setCouponStatus] = useState(true);

  const pathname = window.location.pathname;

  useEffect(() => {
    setUrlName(pathname);
  }, [pathname]);

  // const couponStatusHandler = () => {
  //   setCouponStatus(false);
  // };

  // const couponText = "offre de bienvenue : -20% SUR TA COMMANDE dans TOUS NOS RESTAURANTS ";
  let textHeroImg;
  let dataSelector = 0;

  if (urlName === "/pickup") {
    textHeroImg =
      " LES MEILLEURS RESTAURANTS DE BOBIGNY, à emporter ou déguster sur place";
  } else if (urlName === "/livraison") {
    textHeroImg = " LES MEILLEURS RESTAURANTS DE BOBIGNY, LIVRÉS CHEZ VOUS";
    dataSelector = 1
  }

  return (
    <div>
      {/* {couponStatus && (
        <div className="coupon-wrapper">
          <div className="coupon-wrapper-text">{couponText}</div>
          <button onClick={couponStatusHandler} className="coupon-btn">
            <CloseIcon />
          </button>
        </div>
      )} */}
      <DeliveryHeroImg text={textHeroImg} />
      <div className="delivery-info-wrapper">
        <div className="delivery-info-btn-wrapper">
          {LabKitchenData[dataSelector].map((data) => {
            return (
              <div key={data.id}>
                <DeliveryBtn kitchenInfo={data} />
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DeliveryInfo;
