import HeroImg from "../../assets/main/Hero.png";
import ButtonFormat from "../utils/ButtonFormat/ButtonFormat";
import Footer from "../utils/footer/Footer";

import "./HomeInfo.css";

const HomeInfo = () => {
  return (
    <div>
      <div className="home-info-wrapper">
        <div className="home-text-wrapper">
          <div className="home-text-title">
            TOUS NOS RESTAURANTS, SOUS LE MÊME TOIT
          </div>
          <div className="home-text-desc">
            Labkitchen, c'est un groupe de restaurateurs indépendants qui ont
            rassemblé leurs forces pour vous offrir un large choix de
            spécialités, disponibles à un seul et unique endroit! Italien,
            hawaïen, oriental, asiatique... vous trouverez de tout et des
            recettes qui changent au rythme des saisons! Livraison et collecte
            sur place disponibles.{" "}
          </div>
          <div className="button-format-wrapper">
            <div className="home-coupon-wrapper">
              <ButtonFormat url={"/livraison"}>LIVRAISON</ButtonFormat>
              {/* <span className="home-coupon">-20%</span> */}
            </div>
            <div className="home-coupon-wrapper">
              <ButtonFormat url={"/pickup"}>A EMPORTER</ButtonFormat>
              {/* <span className="home-coupon">-20% </span> */}
            </div>
          </div>
        </div>
        <div className="hero-img-wrapper">
          <img src={HeroImg} alt="Main orders" className="hero-img" />
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default HomeInfo;
