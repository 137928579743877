import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import "./ButtonFormat.css";
import { NavLink } from "react-router-dom";

const ButtonFormat = ({ children, url }) => {
  return (
    <NavLink to={url} className="nav-wrapper">
      <button className="btn-wrapper">
        <ArrowForwardIcon className="arrow-style" />
        <div className="btn-text">{children}</div>
      </button>
    </NavLink>
  );
};

export default ButtonFormat;
