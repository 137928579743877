import { Navigate, Route, Routes } from "react-router-dom";
import NavBar from "./components/utils/navbar/NavBar";
import HomeScreen from "./views/HomeScreen";
import Delivery from "./views/Delivery";

function App() {
  return (
    <div className="App">
      <div style={{ height: "100px" }}></div>
      <NavBar />
      <Routes>
        <Route path="/commander" element={<HomeScreen />} />
        <Route path="/livraison" element={<Delivery />} />
        <Route path="/pickup" element={<Delivery />} />
        <Route path="*" element={<Navigate to="/commander" replace />} />
      </Routes>
    </div>
  );
}

export default App;
