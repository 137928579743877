import HomeInfo from "../components/homepage/HomeInfo";

const HomeScreen = (params) => {
  return (
    <div>
      <HomeInfo />
    </div>
  );
};

export default HomeScreen;
